import React, { useEffect, useState } from "react"
import "./header.css"
import "./styles.scss"
import "animate.css"
import { Link } from "gatsby"
import image1 from "../images/ImageandWord-01.png"

export default function Header(props) {
  //CSS ELEMENT
  useEffect(() => {
    let elements = document.querySelectorAll(".rolling-text")

    elements.forEach(element => {
      let innerText = element.innerText
      element.innerHTML = ""

      let textContainer = document.createElement("div")
      textContainer.classList.add("block")

      for (let letter of innerText) {
        let span = document.createElement("span")
        span.innerText = letter.trim() === "" ? "\xa0" : letter
        span.classList.add("letter")
        textContainer.appendChild(span)
      }

      element.appendChild(textContainer)
      element.appendChild(textContainer.cloneNode(true))
    })
  }, [])
  //MOBILE NAV TRIGGER
  const [mobileNav, setMobileNav] = useState()
  const openMobileNav = e => {
    e.preventDefault()
    setMobileNav(true)
  }
  const closeMobileNav = e => {
    e.preventDefault()
    setMobileNav(false)
  }
  //SCROLL UP/DOWN DETECTION
  const [lastScroll, setLastScroll] = useState(0)
  const [direction, setDirection] = useState("scrolling up")
  useEffect(() => {
    if (props.scrollStatus === "started") {
      setLastScroll(props.frameValue)
    } else if (props.frameValue === 0) {
      setDirection("scrolling up")
    }
    let lazt = lastScroll
    let zcroll = props.frameValue
    setDirection(zcroll > lazt ? "scrolling down" : "scrolling up")
  }, [props.frameValue])

  const activeStyles = {
    borderBottom: "1px solid #be202e",
    paddingBottom: "0px",
  }

  return (
    <div>
      <header
        className={
          direction === "scrolling up"
            ? "header"
            : direction === "scrolling down"
            ? "header-hidden"
            : ""
        }
      >
        <Link className="logo-box" to="/">
          <div className="logo">
            <img
              src={image1}
              alt="Logo"
              style={{
                width: "10vw",
                height: "10vw",
                minWidth: "115px",
                minHeight: "102px",
              }}
            />
          </div>
        </Link>
        <nav className="nav">
          <div>
            <ul className="nav-list">
              <li>
                <a
                  className="list-box"
                  href="https://www.paypal.com/donate/?hosted_button_id=UHGSRNCC822XC"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="rolling-text"
                    style={
                      props.frameValue > 4700 &&
                      props.path === "/"
                        ? { color: "white" }
                        : (props.frameValue > 2800 &&
                            props.path === "/what-we-do") ||
                          (props.frameValue > 2800 &&
                            props.path === "/what-we-do/")
                        ? { color: "white" }
                        : props.path === "/about" ||
                          props.path === "/about/"
                        ? { color: "white" }
                        : {}
                    }
                  >
                    donate
                  </div>
                </a>
              </li>
              <li>
                <Link
                  className="list-box"
                  to="/what-we-do/#home"
                  activeStyle={activeStyles}
                >
                  <div
                    className="rolling-text"
                    style={
                      props.frameValue > 4700 &&
                      props.path === "/"
                        ? { color: "white" }
                        : (props.frameValue > 2800 &&
                            props.path === "/what-we-do") ||
                          (props.frameValue > 2800 &&
                            props.path === "/what-we-do/")
                        ? { color: "white" }
                        : props.path === "/about" ||
                          props.path === "/about/"
                        ? { color: "white" }
                        : {}
                    }
                  >
                    what we do
                  </div>
                </Link>
              </li>
              {/* <li>
                <a
                  className="list-box"
                  href="https://www.beytna-store.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="rolling-text"
                    style={
                      props.frameValue > 4700 &&
                      props.path === "/"
                        ? { color: "white" }
                        : (props.frameValue > 2800 &&
                            props.path === "/what-we-do") ||
                          (props.frameValue > 2800 &&
                            props.path === "/what-we-do/")
                        ? { color: "white" }
                        : props.path === "/about" ||
                          props.path === "/about/"
                        ? { color: "white" }
                        : {}
                    }
                  >
                    shop
                  </div>
                </a>
              </li> */}
              <li>
                <Link className="list-box" to="/#events">
                  <div
                    className="rolling-text"
                    style={
                      props.frameValue > 4700 &&
                      props.path === "/"
                        ? { color: "white" }
                        : (props.frameValue > 2800 &&
                            props.path === "/what-we-do") ||
                          (props.frameValue > 2800 &&
                            props.path === "/what-we-do/")
                        ? { color: "white" }
                        : props.path === "/about" ||
                          props.path === "/about/"
                        ? { color: "white" }
                        : {}
                    }
                  >
                    events
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="list-box"
                  to="/about/#home"
                >
                  <div
                    className="rolling-text"
                    style={
                      props.frameValue > 4700 &&
                      props.path === "/"
                        ? { color: "white" }
                        : (props.frameValue > 2800 &&
                            props.path === "/what-we-do") ||
                          (props.frameValue > 2800 &&
                            props.path === "/what-we-do/")
                        ? { color: "white" }
                        : props.path === "/about" ||
                          props.path === "/about/"
                        ? { color: "white" }
                        : {}
                    }
                  >
                    about
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="list-box"
                  to="/contact/#home"
                >
                  <div
                    className="rolling-text"
                    style={
                      props.frameValue > 4700 &&
                      props.path === "/"
                        ? { color: "white" }
                        : (props.frameValue > 2800 &&
                            props.path === "/what-we-do") ||
                          (props.frameValue > 2800 &&
                            props.path === "/what-we-do/")
                        ? { color: "white" }
                        : props.path === "/about" ||
                          props.path === "/about/"
                        ? { color: "white" }
                        : {}
                    }
                  >
                    Contact
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <a
          className="nav-toggle"
          href="/#"
          onClick={openMobileNav}
          style={
            props.frameValue > 3000 && props.path === "/"
              ? { color: "white" }
              : props.path === "/about" ||
                props.path === "/about/"
              ? { color: "white" }
              : {}
          }
        >
          menu
        </a>
      </header>
      <div
        className={
          mobileNav === true
            ? "nav-mobile wipe-in-down"
            : mobileNav === false
            ? "nav-mobile wipe-out-up"
            : "hidden"
        }
      >
        <div className="nav-mobile-background">
          <a
            className={
              mobileNav === true
                ? "animate__animated animate__fadeInDown nav-mobile-dot"
                : mobileNav === false
                ? "animate__animated animate__fadeOutUp animate__delay-1s nav-mobile-dot"
                : "hidden"
            }
            href="/#"
            aria-label="close nav"
            aria-current="page"
            onClick={closeMobileNav}
          >
            {" "}
          </a>
          <a
            className={
              mobileNav === true
                ? "animate__animated animate__fadeInDown nav-mobile-close"
                : mobileNav === false
                ? "animate__animated animate__fadeOutUp animate__delay-1s nav-mobile-close"
                : "hidden"
            }
            href="/#"
            onClick={closeMobileNav}
          >
            close
          </a>
          <div className="nav-mobile-list">
            <ul
              style={{
                display: "flex",
                padding: "0",
                flexDirection: "column",
                listStyle: "none",
              }}
            >
            <li className="nav-movile-item">
              <div className="line-p">
                <div className="line-c">
                  <a
                    className={
                      mobileNav === true
                        ? "animate__animated animate__slideInUp line-t delay-1"
                        : mobileNav === false
                        ? "animate__animated animate__slideOutDown line-t delay-5"
                        : "hidden"
                    }
                    href="https://www.paypal.com/donate/?hosted_button_id=UHGSRNCC822XC"
                    target="_blank"
                    rel="noreferrer"
                  >
                    donate
                  </a>
                </div>
              </div>
            </li>
              <li className="nav-movile-item">
                <div className="line-p">
                  <div className="line-c">
                    <Link
                      className={
                        mobileNav === true
                          ? "animate__animated animate__slideInUp line-t delay-1"
                          : mobileNav === false
                          ? "animate__animated animate__slideOutDown line-t delay-5"
                          : "hidden"
                      }
                      to="/what-we-do"
                      onClick="setTimeout(()=>{closeMobileNav}, 500)"
                    >
                      what we do
                    </Link>
                  </div>
                </div>
              </li>
              {/* <li className="nav-movile-item">
                <div className="line-p">
                  <div className="line-c">
                    <a
                      className={
                        mobileNav === true
                          ? "animate__animated animate__slideInUp line-t delay-2"
                          : mobileNav === false
                          ? "animate__animated animate__slideOutDown line-t delay-4"
                          : "hidden"
                      }
                      href="https://www.beytna-store.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      shop
                    </a>
                  </div>
                </div>
              </li> */}
              <li className="nav-movile-item">
                <div className="line-p">
                  <div className="line-c">
                    <Link
                      className={
                        mobileNav === true
                          ? "animate__animated animate__slideInUp line-t delay-3"
                          : mobileNav === false
                          ? "animate__animated animate__slideOutDown line-t delay-3"
                          : "hidden"
                      }
                      to="/#events"
                      onClick="setTimeout(()=>{closeMobileNav}, 500)"
                    >
                      events
                    </Link>
                  </div>
                </div>
              </li>
              <li className="nav-movile-item">
                <div className="line-p">
                  <div className="line-c">
                    <Link
                      className={
                        mobileNav === true
                          ? "animate__animated animate__slideInUp line-t delay-4"
                          : mobileNav === false
                          ? "animate__animated animate__slideOutDown line-t delay-2"
                          : "hidden"
                      }
                      to="/about"
                      onClick="setTimeout(()=>{closeMobileNav}, 500)"
                    >
                      about
                    </Link>
                  </div>
                </div>
              </li>
              <li className="nav-movile-item">
                <div className="line-p">
                  <div className="line-c">
                    <Link
                      className={
                        mobileNav === true
                          ? "animate__animated animate__slideInUp animate__faster line-t delay-5"
                          : mobileNav === false
                          ? "animate__animated animate__slideOutDown animate__faster line-t delay-1"
                          : "hidden"
                      }
                      to="/contact"
                      onClick="setTimeout(()=>{closeMobileNav}, 500)"
                    >
                      contact
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
            <div className="nav-mobile-email">
              <a
                className={
                  mobileNav === true
                    ? "animate__animated animate__slideInUp animate__faster animate__delay-1s email-tag"
                    : mobileNav === false
                    ? "animate__animated animate__slideOutDown animate__faster delay-1 email-tag "
                    : "hidden"
                }
                href="mailto:info@beytna.foundation"
              >
                <span className="email-text">info@beytna.foundation</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
