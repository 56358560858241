import React, { useState, useRef, useEffect, createContext } from "react"
import Header from "./header"
import "./layout.css"
import "@fontsource/inter"
import "@fontsource/libre-baskerville"
import { Scrollbars } from "react-custom-scrollbars-2"
import { Helmet } from "react-helmet"

export const UserStateContext = createContext()

export default function Layout({ children, location }) {
  const [frameValue, setFrameValue] = useState(0)
  const [isSelectable, setIsSelectable] = useState("stopped")
  const [isScrollable, setIsScrollable] = useState(false)

  const scrollbars = useRef()

  const [init, setInit] = useState(false)

  useEffect(() => {
    const values = scrollbars.current.getValues()
    if (values?.scrollHeight > values?.clientHeight) setIsScrollable(true)
  }, [init])
  console.log(isScrollable);

  useEffect(() => {
    setInit(true)
  }, [])
  return (
    <Scrollbars
      ref={scrollbars}
      onScrollStart={() => setIsSelectable("started")}
      onScrollStop={() => setIsSelectable("stopped")}
      onScrollFrame={value => {
        setFrameValue(value)
      }}
      hideTracksWhenNotNeeded
      autoHide
      autoHeight
      autoHeightMin={0}
      autoHeightMax="100vh"
      //style={{ width: "100%", height: "100%", marginRight: "-17px!important", marginBottom: "-20px" }}
      className="scrollbar-main"
    >
    <UserStateContext.Provider value={frameValue}>
      <div className="base-layer">
          <Header
            scrollStatus={isSelectable}
            frameValue={frameValue.scrollTop}
            path={location.pathname}
          />
          <Helmet>
            <meta charSet="utf-8" />
            <title>Beytna Charitable Foundation</title>
          </Helmet>
          <script src="https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js"></script>
          {children}
      </div>
    </UserStateContext.Provider>
        </Scrollbars>
  )
}
